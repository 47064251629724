import React from 'react';
import { PropTypes } from 'prop-types';
import { 
  Map as LeafletMap,
  TileLayer,
  GeoJSON,
  LayersControl,
  ZoomControl,
  ScaleControl
} from 'react-leaflet';
import { GoogleLayer } from 'react-leaflet-google/lib';

const { BaseLayer } = LayersControl;
const key = 'AIzaSyCx0AFQAaTQPAbfkoUHwMsb_JaEd-teMKU';

const terrain = 'TERRAIN';
// const road = 'ROADMAP';
const satellite = 'SATELLITE';
const hydrid = 'HYBRID';

class Map extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      lat: null,
      lng: null,
      info: null,
      baseLayer: BaseLayer.checked
    }

  };

  byte2Hex = (byte) => {
    var hex = Number(byte).toString(16);
    if (hex.length < 2) {
      hex = "0" + hex;
    }
    return hex;
  };
  
  rgb2Hex = (r, g, b) => {
    var red = this.byte2Hex(r);
    var green = this.byte2Hex(g);
    var blue = this.byte2Hex(b);
    return '#' + red + green + blue;
  };

  getColor = (d) => {
    return d > 380 ? this.rgb2Hex(30, 178, 255) :
    d > 240 ? this.rgb2Hex(43, 186, 255) :
    d > 180 ? this.rgb2Hex(55, 193, 255) :
    d > 90 ? this.rgb2Hex(65, 200, 255) :
    d > 50 ? this.rgb2Hex(79, 210, 255) :
    d > 25 ? this.rgb2Hex(94, 223, 255) :
    d > 10 ? this.rgb2Hex(138, 227, 255) :
    this.rgb2Hex(51, 102, 0);
  }

  bathStyle = (feature) => {
    return {
      color: this.getColor(feature.properties.DEPTH),
      weight: 2,
      opacity: 1,
    };
  }

  getFeatureType = (featureProperties) => {
    const keys = Object.keys(featureProperties);

    if (keys.indexOf('type') === -1) {
        return 'pond'
    } else if (keys.indexOf('type') > -1) {
        return 'bay'
    } else {
      return 'other';
    }
  }

  onMouseOver = (e) => {
    let info = {
      type: this.getFeatureType(e.layer.feature.properties),
      lat: parseFloat(e.latlng.lat) ? parseFloat(e.latlng.lat).toFixed(5) : null,
      lng: parseFloat(e.latlng.lng) ? parseFloat(e.latlng.lng).toFixed(5) : null,
      info: e.layer.feature.properties
    }
    this.props.onContextChange(info);
  }

  onMouseOut = (e) => {
    let info = {
      type: null,
      lat: null,
      lng: null,
      info: {}
    }
    this.props.onContextChange(info);
  }

  onClick = (e) => {
    let info = {
      type: this.getFeatureType(e.layer.feature.properties),
      lat: parseFloat(e.latlng.lat) ? parseFloat(e.latlng.lat).toFixed(5) : null,
      lng: parseFloat(e.latlng.lng) ? parseFloat(e.latlng.lng).toFixed(5) : null,
      info: e.layer.feature.properties
    }

    this.props.onClick(info);

    
  }


  render() {
    const position = [this.props.position.lat, this.props.position.lng];
    const zoom = this.props.position.zoom;

    let overlayLayers = this.props.layers.filter(layer => layer.data != null).map(layer => {
      return (
        <GeoJSON
          key={layer.key}
          data={layer.data}
          style={layer.style === "BATHYMETRY_STYLE" ? this.bathStyle : layer.style}
          onMouseOver={this.onMouseOver}
          onMouseOut={this.onMouseOut}
          onClick={this.onClick} 
          ref="geojson"/>
      )
    });
        

    return (
      <LeafletMap ref="foo" center={position} zoom={zoom} control whenReady={this.mapInit} onbaselayerchange={this.baseLayerChange} >
        <LayersControl position="topleft">
          <ZoomControl  position="bottomright" />
          <ScaleControl  position="bottomleft" imperial={false}/>
          <BaseLayer name="Default">
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url='http://{s}.tile.osm.org/{z}/{x}/{y}.png' />
          </BaseLayer>
          <BaseLayer   name='Satellite' >
            <GoogleLayer googlekey={key}  maptype={satellite} />
          </BaseLayer>
          <BaseLayer  name='Terrain' checked>
            <GoogleLayer googlekey={key}  maptype={terrain}/>
          </BaseLayer>
          <BaseLayer  name='Hybrid' >
            <GoogleLayer googlekey={key}  maptype={hydrid}  libraries={['geometry', 'places']} />
          </BaseLayer>  
          
          {overlayLayers}
          
        </LayersControl>
      </LeafletMap>
    );
  }
}

Map.propTypes = {
  position: PropTypes.object.isRequired,
  layers: PropTypes.array.isRequired,
  onContextChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

export default Map;