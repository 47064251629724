import React from 'react';
import shrimp from './images/shrimp-white.png';
import fish from './images/fish-white.png';
import money from './images/money-white.png';
import './App.scss';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Header from './Header';
import Map from './Map';
import Footer from './Footer';
import Loader from './Loader';
import update from 'immutability-helper';
import layerOptions from './constants/layerOptions.json';
import { Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';
import { Checkbox, Button } from 'antd';
import 'antd/lib/checkbox/style/index.css';
import 'antd/lib/button/style/index.css';
import './leaflet.css';
import findIndex from 'lodash.findindex';

const mapPositions = {
  "chumphon": {
    "lat": 10.312075047092328,
    "lng": 99.23367487207031,
    "zoom": 11
  },
  "surat_thani": {
    "lat": 9.14011,
    "lng": 99.33311,
    "zoom": 10
  },
  "center": {
    "lat": 9.727480633849985, 
    "lng": 99.38405023828125,
    "zoom": 9
  }
};

const bigContext = {
  'bottom': '206px',
  'height': '206px'
};

const smallContext = {
  'bottom': '32px',
  'height': '32px'
};

const nitrogenTitle = 'Nitrogen balance (kg N per year)';  
const pieSliceColors = ['#9366ff', '#3bade1', '#78b636'];
const nitAndPhosChartData = {
  datasets: [{
    data: [],
    backgroundColor: pieSliceColors,
    borderWidth: 1,
    hoverBackgroundColor: 'rgba(255,99,132,0.4)',
    hoverBorderColor: 'rgba(255,99,132,1)'
  }]
};

const nitAndPhosChartOptions = {
  maintainAspectRatio: false,
  title: {
    display: true,
    text: '',
    fontColor: 'white'
  },
  legend: {
    display: false,
  },
  plugins: {
    datalabels: {
      display: true,
      color: 'white',
      anchor: 'end'
    }
  },
  scales: {
    yAxes: [{
      ticks: {
        fontColor: "rgba(255, 255, 255, 0.7)",
        stepsize: 1000
      }
    }],
    xAxes: [{
      ticks: {
        fontColor: "rgba(255, 255, 255, 0.7)",
        stepsize: 1000
      }
    }]
  }
};

const LAYERKEYS = [
  "ponds_all",
  "load_areas"
];

const AREA0 = {
  area: "Chumphon",
  zones: [
    "Ao Phanang Tak",
    "Pak Hat Bay",
    "Mu Ko Chumphon"
  ]
}

const AREA1 = {
  area: "Surat Thani",
  zones: [
    "Ta Pi river",
    "Tha Thong river",
    "Thun river",
    "Klang river",
    "Don Cha Om river"
  ]
}

const koeff = {
  volume: 1.5,
  stock: 195,
  production: 8.6242,
  ammonia: 0.3744,
  nitrogen: 0.00828,
  algae: 0.001064,
  income: 3.5,
  exter1: 3.3,
  exter2: 30,
  nExc: 0.519,
  nReg: 0.0754,
  nProd: 0.0246
}

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      mapPosition: mapPositions.center,
      layersBase: [],
      layers: [],
      loadingLayers: false,
      context: {type: null, lat: null, lng: null, info: {}},
      contextExpanded: true,
      contextLocked: false,
      zones: [],
      area: {
        area0: {},
        area1: {}
      }
    };
  }

  componentDidMount() {
    this.loadJsons('load_areas', 'ponds_all'); 
  }

  updateMapPosition = (position) => {
    this.setState({ mapPosition: mapPositions[position] });
  }

  loadJsons = (layerKey1, layerKey2) => {
    const optionIndex1 = findIndex(layerOptions, (layer) => { return layer.key === layerKey1 });
    const optionIndex2 = findIndex(layerOptions, (layer) => { return layer.key === layerKey2 });
    
    const layers = [
      {...layerOptions[optionIndex1]},
      {...layerOptions[optionIndex2]},
    ];

    layers.forEach(layer => {
      fetch(layer.source)
      .then((res) => res.json())
      .then((data) => {
        layer.data = data.features;
        layer.fulldata = data.features;
        layer.loaded = true;
        this.setState({ 
            layersBase: [],
            loadingLayers: true
          }, () => {
            const newState = update(this.state, { 
              layersBase: { $set: layers }
            });
            this.setState(newState, () => {
              this.setState({loadingLayers: false});
            });
        });
        if (layer.key === LAYERKEYS[0]) {
          this.calcTotal(AREA0.area);
          this.calcTotal(AREA1.area);
        }
      });
    })
  }


  onLayerChange = (e, layerKey, region, zone) => {
    if (e.target.checked) {
      this.loadLayer(true, layerKey, region, zone);
    } else {
      this.loadLayer(false, layerKey, region, zone);
    }
  }

  isLayerChecked = (layerKey, region, zone) => {
    return (findIndex(this.state.layers, (layer) => { 
        let checkLayer = layer.key === layerKey;
        let checkRegion = findIndex(layer.data, (item) => {
            if (zone)
              return (item.properties.Area.includes(region) && item.properties.Zone.includes(zone));
            else 
              return (item.properties.Area.includes(region));
          }) > -1;
        return checkLayer && checkRegion
    }) > -1);
  }

  loadLayer = (load, layerKey, region, zone) => {
    const optionIndex = findIndex(this.state.layersBase, (layer) => { return layer.key === layerKey }); 
    let layer = {...this.state.layersBase[optionIndex]};
    let layers = this.state.layers;
    let mapPosition = {};

    const layerIndex = layers.findIndex((layer) => { return layer.key === layerKey });

    // - on layer add:
    if (load) {
      // -- if layer already exists:
      if (layerIndex > -1) {
        layer = {...this.state.layers[layerIndex]};
        // filter by region (area):
        layer.data = layer.data.concat(
        layer.fulldata.filter(
            item => {
              if (zone)
                return (item.properties.Area.includes(region) && item.properties.Zone.includes(zone));
              else 
                return (item.properties.Area.includes(region));
            }
          )
        )
        layer.loaded = true;
        layers[layerIndex] = layer;
      // -- if layer is new:
      } else {
        // filter by region (area):
        layer.data = layer.fulldata.filter(
          item => {
            if (zone)
              return (item.properties.Area.includes(region) && item.properties.Zone.includes(zone));
            else 
              return (item.properties.Area.includes(region));
          }
        )
        layer.loaded = true;
        layers = layers.concat(layer);
      }

      // zoom to region:
      // if (region === AREA0.area)
      //   this.updateMapPosition("chumphon");
      // else if (region === AREA1.area)
      //   this.updateMapPosition("surat_thani");

    // - on layer remove:
    } else {
      layer = {...this.state.layers[layerIndex]};
      layer.data = layer.data.filter(
        item => {
          if (zone)
            return (!item.properties.Zone.includes(zone));
          else 
            return (!item.properties.Area.includes(region));
        }
      )
      layers[layerIndex] = layer;
      // reset region's zoom:
      // if(!zone || !this.isLayerChecked(layerKey, region))
      //   this.updateMapPosition("center");
    }
        

    // update map state:
    this.setState({ 
        layers: [],
        loadingLayers: true
      }, () => {
        const newState = update(this.state, { 
          layers: { $set: layers }
        });
        this.setState(newState, () => {
          this.setState({loadingLayers: false});
        });
        
    });
    
  }


  onContextChange = (context) => {
    if (!this.state.contextLocked) {
      this.setState({
        context: context
      });
    }
  }
  
  onLockContext = (context) => {
    if (this.state.contextLocked) {
      this.onUnlockContext();
    } else {
      this.setState({
        context: context,
        contextLocked: true
      });
    }
  }

  onUnlockContext = () => {
    this.setState({ contextLocked: false });
  }

  toggleContext = () => {
    this.setState({ contextExpanded: !this.state.contextExpanded });
  }

  round2 = (n) => {
    return parseFloat(n) ? parseFloat(n).toFixed(2) : null
  }
  round0 = (n) => {
    return parseFloat(n) ? parseFloat(n).toFixed(0) : null
  }
  thousandsSeparator = (n) => {
    return parseFloat(n) ? parseFloat(n).toLocaleString().replace(/,/g," ",) : null
  }
  m2ToHa = (n) => {
    return parseFloat(n) ? parseFloat(n)/10000 : null
  }
  m2ToRai = (n) => {
    return parseFloat(n) ? (parseFloat(n)/10000)*16 : null
  }
  kgToTon = (n) => {
    return parseFloat(n) ? parseFloat(n)/1000 : null
  }

  pondsAreaByZone = (zone) => {
    let layerBase = this.state.layersBase.filter(layer => layer.key === LAYERKEYS[0]);
    let layerBaseFiltered = layerBase[0].fulldata.filter(item => item.properties.Zone === zone);
    let totalPonds = 0;
    layerBaseFiltered.forEach(item => {
      totalPonds += item.properties["Area (m2)"];
    })
    return totalPonds;
  }

  calcBayContext = (zone, koeff) => {
    return (this.pondsAreaByZone(zone) * koeff) / 1000 ;                         
  }

  calcTotal = (area) => {
    let layerPonds = this.state.layersBase.filter(layer => layer.key === LAYERKEYS[0]);
    let layerBays = this.state.layersBase.filter(layer => layer.key === LAYERKEYS[1]);
    
    let layerPondsFiltered = layerPonds[0].fulldata.filter(item => item.properties.Area === area);
    let layerBaysFiltered = layerBays[0].fulldata.filter(item => item.properties.Area === area);
    
    let AREA = {
      area: null,
      totalArea: null,
      totalProduction: null,
      aggArea: null,
      aggNitrogen: null,
      aggAlgae: null,
      income: null,
      exter: null,
      balance: null
    }

    // area Name:
    AREA.area = area;
    // total Area (Ponds): 
    layerPondsFiltered.forEach(item => {
      AREA.totalArea += item.properties["Area (m2)"];
    })
    // total Production: 
    AREA.totalProduction = AREA.totalArea * koeff.production;

    // aggregate Area (Bays): 
    layerBaysFiltered.forEach(item => {
      AREA.aggArea += item.properties["Area (m2)"];
    })

    // aggregate Nitrogen, Algae:
    AREA.aggNitrogen = AREA.totalArea * koeff.ammonia + AREA.totalArea * koeff.nitrogen;
    AREA.aggAlgae = AREA.totalArea * koeff.algae;

    // Income:
    AREA.income = AREA.totalProduction * koeff.income;
    // Externalities:
    AREA.exter = AREA.aggNitrogen / koeff.exter1 * koeff.exter2;
    // Balance:
    AREA.balance = AREA.income - AREA.exter;

    if (area === AREA0.area)
      this.setState({area: {...this.state.area,area0: AREA}});
    else if (area === AREA1.area)
      this.setState({area: {...this.state.area,area1: AREA}});
  }


  render() {
    let nitAndPhosPieCharts = (context) => {
      const nitrogenChartOptions = {
        ...nitAndPhosChartOptions,
        title: { ...nitAndPhosChartOptions.title, text: nitrogenTitle }
      };
      const nitrogenChartData = {
        labels: ['Excretion ', 'Regeneration', 'Algae'],
        datasets: [
          {
            ...nitAndPhosChartData.datasets[0],
            data: [
              this.round2(context.info["Area (m2)"] * koeff.nExc), 
              this.round2(context.info["Area (m2)"] * koeff.nReg), 
              this.round2(context.info["Area (m2)"] * koeff.nProd)
            ]
          }
        ]
      };

      return (
        <Row>
          <Col xs={12} className="bg-dark-08 chart-block">
            <Bar 
              data={ nitrogenChartData }
              width={100}
              height={180}
              options={ nitrogenChartOptions } />
          </Col>
        </Row>
      )
    }

    let displayContext = () => {
      const context = this.state.context;
      switch (context.type) {

        case 'pond':
          return (
            <Row>
              <Col xs={11} lg={4} className="context-title px-0 mt-2">
                <ul className="pl-3">
                  <li>
                    <span>Area (m<sup>2</sup>): </span>
                    <span className="value">
                      {
                        this.thousandsSeparator(
                          this.round0(
                            context.info["Area (m2)"]
                          )
                        )
                      }
                    </span>
                  </li>
                  <li>
                    <span>Volume (m<sup>3</sup>): </span>
                    <span className="value">
                      {
                        this.thousandsSeparator(
                          this.round0(
                            context.info["Area (m2)"] * koeff.volume
                          )
                        )
                      }
                    </span>
                  </li>
                  <li>
                    <span>Total stock (ind.): </span>
                    <span className="value">
                      {
                        this.thousandsSeparator(
                          this.round0(
                            context.info["Area (m2)"] * koeff.stock
                          )
                        )
                      }
                    </span>
                  </li>
                  <li>
                    <span>Production (kg per year): </span>
                    <span className="value">
                      {
                        this.thousandsSeparator(
                          this.round0(
                            context.info["Area (m2)"] * koeff.production
                          )
                        )
                      }
                    </span>
                  </li>
                </ul>
              </Col>
              <Col xs={11} lg={7}> 
                { nitAndPhosPieCharts(context) }
              </Col>
            </Row>    
          );

        case 'bay':
          return (
            <Row>
              <Col xs={11} className="context-title text-left px-0 mt-2">
                <span className="">{context.info["Zone"]}, {context.info["Area"]}</span>
              </Col>
              <Col xs={11} className={this.isLayerChecked(LAYERKEYS[0], context.info["Area"], context.info["Zone"]) ?
                              "d-none"
                              :"context-title text-left px-0 mt-2"}>
                <ul className="pl-3">
                  <li>
                    <span> Bay area (m<sup>2</sup>): </span>
                    <span className="">
                      {
                        this.thousandsSeparator(
                          this.round0(
                            context.info["Area (m2)"]
                          )
                        )
                      }
                    </span>
                  </li>
                </ul>
              </Col>
              <Col xs={11} className={this.isLayerChecked(LAYERKEYS[0], context.info["Area"], context.info["Zone"]) ?
                              "context-title text-left px-0 mt-2"
                              :"d-none"}>
                <ul className="pl-3">
                  <li>
                    <span>Ammonia (ton N per year): </span>
                    <span className="value">
                      {
                        this.thousandsSeparator(
                          this.round0(
                            this.calcBayContext(context.info["Zone"], koeff.ammonia)
                          )
                        )
                      }
                    </span>
                  </li>
                  <li>
                    <span>Particulate nitrogen (ton N per year): </span>
                    <span className="value">
                      {
                        this.thousandsSeparator(
                          this.round0(
                            this.calcBayContext(context.info["Zone"], koeff.nitrogen)
                          )
                        )
                      }
                    </span>
                  </li>
                  <li>
                    <span>Algae (ton chl per year): </span>
                    <span className="value">
                      {
                        this.thousandsSeparator(
                          this.round0(
                            this.calcBayContext(context.info["Zone"], koeff.algae)
                          )
                        )
                      }
                    </span>
                  </li>
                </ul>
              </Col>
              
            </Row>
          );
        default:
          return (
            null
          );
      }
    }

    let mapZones = (layer, area, zones) => {
      let checkList = zones.map(zone => {
        return (
          <Checkbox
            className="col-12"
            key={zone}
            checked={this.isLayerChecked(layer, area, zone)}
            disabled={this.state.loadingLayers}
            onChange={e => this.onLayerChange(e, layer, area, zone)}>
            {zone}
          </Checkbox>
        );
      })
      return checkList;
    }


    return (
      <div className="app">

        <div className="section map">
          <Header></Header>
          <div className="map-and-context">
            <div className={this.state.contextExpanded ? "map-wrapper big-context" : "map-wrapper"}>
              <Map 
                position={this.state.mapPosition}
                layers={this.state.layers} 
                onContextChange={this.onContextChange}
                onClick={this.onLockContext} />
            </div>
            <div className="context pb-0" style={this.state.contextExpanded ? bigContext : smallContext}>
              <Container fluid>
                <Row className={this.state.contextExpanded ? "" : "hide"}>
                  { this.state.loadingLayers ? (
                    <Col xs={8} md={6} className="pl-0 pl-md-3">
                      <div className="loader-padding">
                        <Loader status="Loading layer" inlineStatus={false} />
                      </div>
                    </Col>
                  ) : (
                    <Col xs={8} md={6} className="info pl-0 pl-md-3">
                      { Object.keys(this.state.context.info).length < 1 ? (
                        <ul>
                          <li>Select layers to the right</li>
                          <li>Hover over areas of interest for more information</li>
                          <li>Click area of interest to lock information in the context bar</li>
                        </ul>
                      ) : (
                        <div>
                          { displayContext() }
                        </div>
                      )}
                    </Col>
                  )}
                  <Col xs={4} md={6} className="pr-0 pr-md-3">
                    <Row className="map-selections d-flex flex-wrap justify-content-between">
                      <Col xs={12} lg={6} className="mb-3 mb-lg-0 px-0 row align-content-start">
                        <h4 className="col-12 text-nowrap" onClick={() => this.updateMapPosition('chumphon')}>Chumphon</h4>
                        <div className="category col-md-6">
                          <div className="category-label text-nowrap">Aquaculture</div>
                          <Checkbox
                            checked={this.isLayerChecked(LAYERKEYS[0], AREA0.area)}
                            disabled={this.state.loadingLayers}
                            onChange={e => this.onLayerChange(e, LAYERKEYS[0], AREA0.area)}>
                            Shrimp Ponds
                          </Checkbox>
                          <div className="d-flex flex-wrap">
                            <hr className="border-primary my-1 w-100" />
                            { mapZones(LAYERKEYS[0], AREA0.area, AREA0.zones) }
                          </div>
                        </div>
                        <div className="category col-md-6">
                          <div className="category-label text-nowrap">Coastal areas</div>
                          <Checkbox
                            checked={this.isLayerChecked(LAYERKEYS[1], AREA0.area)}
                            disabled={this.state.loadingLayers}
                            onChange={e => this.onLayerChange(e, LAYERKEYS[1], AREA0.area)}>
                            Bays
                          </Checkbox>
                          <div className="d-flex flex-wrap">
                            <hr className="border-primary my-1 w-100" />
                            { mapZones(LAYERKEYS[1], AREA0.area, AREA0.zones) }
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} lg={6} className="mb-3 mb-lg-0 px-0 row align-content-start">
                        <h4 className="col-12 text-nowrap" onClick={() => this.updateMapPosition('surat_thani')}>Surat Thani</h4>
                        <div className="category col-md-6">
                          <div className="category-label text-nowrap">Aquaculture</div>
                          <Checkbox
                            checked={this.isLayerChecked(LAYERKEYS[0], AREA1.area)}
                            disabled={this.state.loadingLayers}
                            onChange={e => this.onLayerChange(e, LAYERKEYS[0], AREA1.area)}>
                            Shrimp Ponds
                          </Checkbox>
                          <div className="d-flex flex-wrap">
                            <hr className="border-primary my-1 w-100" />
                            { mapZones(LAYERKEYS[0], AREA1.area, AREA1.zones) }
                          </div>
                        </div>
                        <div className="category col-md-6">
                          <div className="category-label text-nowrap">Coastal areas</div>
                          <Checkbox
                            checked={this.isLayerChecked(LAYERKEYS[1], AREA1.area)}
                            disabled={this.state.loadingLayers}
                            onChange={e => this.onLayerChange(e, LAYERKEYS[1], AREA1.area)}>
                            Bays
                          </Checkbox>
                          <div className="d-flex flex-wrap">
                            <hr className="border-primary my-1 w-100" />
                            { mapZones(LAYERKEYS[1], AREA1.area, AREA1.zones) }
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Button 
                  className="context-collapse-btn"
                  size="small"
                  type="primary" 
                  icon={this.state.contextExpanded ? "down-square" : "up-square"}
                  title={this.state.contextExpanded ? "Collapse" : "Expand"}
                  onClick={this.toggleContext}>
                </Button>
                { this.state.contextLocked ? (
                  <Button 
                    className="context-locked-btn"
                    size="small"
                    type="primary" 
                    icon="unlock"
                    title="Unlock context information"
                    onClick={this.onUnlockContext}>
                  </Button>
                ) : null }
              </Container>
            </div>
          </div>
        </div>

        <div className="section about">
          <div className="info justify-content-lg-around">
            <div className="info-col col-12 col-md">
              <div className="icon">
                <Image src={shrimp} />
              </div>
              <div className="description mb-5">
                <p className="label">Shrimp Farming {AREA0.area}</p>
                <p className="value">
                  Area: {
                    this.thousandsSeparator(
                      this.round0(
                        this.m2ToHa(this.state.area.area0.totalArea)
                      )
                    )
                  } ha
                </p>
                <p className="value">
                  {
                    this.thousandsSeparator(
                      this.round0(
                        this.m2ToRai(this.state.area.area0.totalArea)
                      )
                    )
                  } rai
                </p>
                <p className="value">
                  Production: {
                    this.thousandsSeparator(
                      this.round0(
                        this.kgToTon(this.state.area.area0.totalProduction)
                      )
                    )
                  } ton per year
                </p>
              </div>
              <div className="description">
                <p className="label">Shrimp Farming {AREA1.area}</p>
                <p className="value">
                  Area: {
                    this.thousandsSeparator(
                      this.round0(
                        this.m2ToHa(this.state.area.area1.totalArea)
                      )
                    )
                  } ha
                </p>
                <p className="value">
                  {
                    this.thousandsSeparator(
                      this.round0(
                        this.m2ToRai(this.state.area.area1.totalArea)
                      )
                    )
                  } rai
                </p>
                <p className="value">
                  Production: {
                    this.thousandsSeparator(
                      this.round0(
                        this.kgToTon(this.state.area.area1.totalProduction)
                      )
                    )
                  } ton per year
                </p>
              </div>
            </div>
            <div className="info-col col-12 col-md">
              <div className="icon">
                <Image src={fish} />
              </div>
              <div className="description mb-5">
                <p className="label">Coastal Waters {AREA0.area}</p>
                <p className="value">
                  Area: {
                    this.thousandsSeparator(
                      this.round0(
                        this.m2ToHa(this.state.area.area0.aggArea)
                      )
                    )
                  } ha
                </p>
                <p className="value">
                  Nitrogen load: {
                    this.thousandsSeparator(
                      this.round0(
                        this.kgToTon(this.state.area.area0.aggNitrogen)
                      )
                    )
                  } ton N per year
                </p>
                <p className="value">
                  Algal load: {
                    this.thousandsSeparator(
                      this.round0(
                         this.kgToTon(this.state.area.area0.aggAlgae)
                      )
                    )
                  } ton chl per year
                </p>
              </div>
              <div className="description">
                <p className="label">Coastal Waters {AREA1.area}</p>
                <p className="value">
                  Area: {
                    this.thousandsSeparator(
                      this.round0(
                        this.m2ToHa(this.state.area.area1.aggArea)
                      )
                    )
                  } ha
                </p>
                <p className="value">
                  Nitrogen load: {
                    this.thousandsSeparator(
                      this.round0(
                        this.kgToTon(this.state.area.area1.aggNitrogen)
                      )
                    )
                  } ton N per year
                </p>
                <p className="value">
                  Algal load: {
                    this.thousandsSeparator(
                      this.round0(
                         this.kgToTon(this.state.area.area1.aggAlgae)
                      )
                    )
                  } ton chl per year
                </p>
              </div>
            </div>
            <div className="info-col col-12 col-md">
              <div className="icon">
                <Image src={money} />
              </div>
              <div className="description mb-5">
                <p className="label">Economics {AREA0.area}</p>
                <p className="value">
                  Income: {
                    this.thousandsSeparator(
                      this.round0(
                        this.kgToTon(this.state.area.area0.income)
                      )
                    )
                  } K USD per year
                </p>
                <p className="value">
                  Externalities: -{
                    this.thousandsSeparator(
                      this.round0(
                        this.kgToTon(this.state.area.area0.exter)
                      )
                    )
                  } K USD per year
                </p>
                <p className="value">
                  Balance: {
                    this.thousandsSeparator(
                      this.round0(
                        this.kgToTon(this.state.area.area0.balance)
                      )
                    )
                  } K USD per year
                </p>
              </div>
              <div className="description">
                <p className="label">Economics {AREA1.area}</p>
                <p className="value">
                  Income: {
                    this.thousandsSeparator(
                      this.round0(
                        this.kgToTon(this.state.area.area1.income)
                      )
                    )
                  } K USD per year
                </p>
                <p className="value">
                  Externalities: -{
                    this.thousandsSeparator(
                      this.round0(
                        this.kgToTon(this.state.area.area1.exter)
                      )
                    )
                  } K USD per year
                </p>
                <p className="value">
                  Balance: {
                    this.thousandsSeparator(
                      this.round0(
                        this.kgToTon(this.state.area.area1.balance)
                      )
                    )
                  } K USD per year
                </p>
              </div>
            </div>
          </div>
          <Footer></Footer>
        </div>
      
      </div>
    );
  }
}

export default App;
