import React from 'react';
import ecoShrimpLogo from './images/EcoShrimp.png';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';


class Header extends React.Component {
  constructor() {
    super();

    this.state = {
    };
  }

  render() {
    return (
      <Navbar className="py-0">
        <Navbar.Brand className="py-1">
          <Image className="logo" src={ecoShrimpLogo} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav" className="justify-content-center">
        </Navbar.Collapse>
      </Navbar>
    );
  }

}

export default Header;
