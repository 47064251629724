import React from 'react';
import { PropTypes } from 'prop-types';
import './loader.scss';

const Loader = ({status, inlineStatus = false }) => {
  const inlineLoader = () => {
    return (
      <div className="inline-loader">
        <span className="loader center">
          <div className="lds-ripple">
            <div></div>
            <div></div>
          </div>
        </span>

        <span className="status">
          { status }
        </span>
      </div>
    )
  }

  const blockLoader = () => {
    return (
      <div className="loader center">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>

        <div>
          { status }
        </div>
      </div>
    )
  }

  return inlineStatus ? inlineLoader() : blockLoader();
}

Loader.propTypes = {
  status: PropTypes.string.isRequired,
  inlineStatus: PropTypes.bool
};

export default Loader;