import React from 'react';
import longlineLogo from './images/Longline_logo_web.png';
import sfpLogo from './images/SFP-Logo-Blue.png';
import spLogo from './images/Sea_Pact_Logo.png';
import wfLogo from './images/walmart-foundation-logo.jpg';
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

class Footer extends React.Component {
  constructor() {
    super();

    this.state = {
    };
  }

  render() {
    return (
      <div className="footer">
        <Row className="justify-content-center text-center pb-4 justify-content-md-around text-md-left pb-md-0">
          <Col xs={{span: 12}} md={{span: 7}} className="mb-5 mb-md-0">
            <p>Partners</p>
            <a href="https://www.longline.co.uk/" target="_blank" rel="noopener noreferrer">
              <Image 
                style={{"marginRight": "20px"}}
                className="logo" 
                src={longlineLogo} 
                title="Longline Environment" />
            </a>

            <a href="https://www.sustainablefish.org/" target="_blank" rel="noopener noreferrer">
              <Image className="logo" src={sfpLogo} title="Sustainable Fisheries Partnership" />
            </a>
          </Col>
          <Col xs={{span: 12}} md={{span: 3}} className="text-center text-md-right">
            <div>
              <p>Donors</p>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <Image style={{"marginRight": "20px"}} className="logo" src={spLogo} title="Sea Pact" />
              </a>
              <a href="#" target="_blank" rel="noopener noreferrer">
                <Image className="logo" src={wfLogo} title="Walmart Foundation" />
              </a>
            </div>
            
          </Col>
        </Row>
      </div>
    );
  }
}

export default Footer;
